<template>
  <div class="sales-addons-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="saleAddonsList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="handleRowClicked"
                             @load:more="getSalesAddons"/>


    <!-- insert new sale addon prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertNewSaleAddonPromptStatus"
      @close="insertNewSaleAddonPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertSaleAddonBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.addons.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertNewSaleAddonPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <keep-alive>
            <insert-sale-addon :in-modal="true" @inserted="handleReloadList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="insertSaleAddon" v-show="false" @click="insertNewSaleAddonPromptStatus = true"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import { getSaleAddons } from "../../../../../http/requests/club/salesAddons";
  import {addComma, checkUserPermissions} from "../../../../../assets/js/functions";
  import CustomIcon from "../../../../../components/customIcon/customIcon";
  import InsertSaleAddon from "../insert/insertSaleAddon";
  import {getCharacters} from "../../../../../http/requests/characters";
  import historyStates from "@/mixins/historyStates";

  export default {
    name: "salesAddonsList",
    metaInfo () {
      return {
        title: this.$t('sales.addons.title')
      }
    },
    components: {InsertSaleAddon, CustomIcon},
    mixins: [historyStates],
    data () {
      return {
        insertNewSaleAddonPromptStatus: false,
        selectedRow: {},
        filters: {},
        selectedFilters: [],
        sorts: ['order[0]=created_at,desc'],
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        options: {
          id: 'saleAddonsList',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'price',
            i18n: 'sales.addons.list.table.header.price',
            width: 'calc(100% / 10)',
            minWidth: 150,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'equals',
                name: 'برابر باشد',
                i18n: 'draggableTable.filter.types.equals',
                id: 1
              },
              {
                icon: 'not-equal',
                name: 'برابر نباشد',
                i18n: 'draggableTable.filter.types.notEqual',
                id: 2
              },
              {
                icon: 'less-than-equal',
                name: 'کوچکتر مساوی',
                i18n: 'draggableTable.filter.types.lessThanEquals',
                id: 4
              },
              {
                icon: 'greater-than-equal',
                name: 'بزرگتر مساوی',
                i18n: 'draggableTable.filter.types.greaterThanEquals',
                id: 3
              }
            ],
            // footer: {
            //   type: 'auto-sum',
            //   textType: 'price'
            // }
          },
          {
            field: 'character',
            i18n: 'sales.addons.list.table.header.character',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 200,
            sortable: true,
            filter: true,
            filterType: 'select',
            filterTypes: [
              {
                label: this.$t('sales.addons.labels.all'),
                value: 0
              },
            ]
          },
          {
            field: 'name',
            i18n: 'sales.addons.list.table.header.name',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 200,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'rowNumber',
            i18n: 'sales.addons.list.table.header.row',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 100,
            footer: {}
          }
        ],
        actions: [
          {
            toolbar: [
              {
                id: 'insertSaleAddon',
                icon: 'PLUS',
                iconPack: 'useral',
                color: 'success',
                permission: 'sale_addon.create'
              }
            ],
            leftToolbar: [
              {
                id: 'printTable',
                icon: 'PRINT',
                iconPack: 'useral'
              },
              {
                id: 'downloadTable',
                icon: 'DOWNLOAD',
                iconPack: 'useral'
              },
              {
                id: {name: 'saleAddonsTrash'},
                type: 'link',
                icon: 'TRASH',
                iconPack: 'useral',
                permission: 'sale_addon.delete'
              },
              {
                id: 'settingTable',
                icon: 'icon-settings',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    created () {
      this.$nextTick(() => {
        this.$store.dispatch('auth/setAccessToken')
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      })

      if (checkUserPermissions('character.show')) {
        this.getCharacters()
      }
      if (history.state.hasOwnProperty('data') && this.$store.state.helper.prevRoute.name !== null) {
        this.getTableInfoFromHistory()
      } else {
        this.getSalesAddons()
      }
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getCharacters () {
        getCharacters().then(response => {
          const characters = response.data.data
          const character_index = this.columnsLabel.map(e => e.field).indexOf('character')
          if (character_index > -1) {
            characters.forEach(character => {
              this.columnsLabel[character_index].filterTypes.push({
                value: character.id,
                label: character.name
              })
            })
          }
        })
      },
      getSalesAddons () {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (this.data.length < this.total_count || !this.total_count) {
            if (this.$refs.saleAddonsList && this.data.length === 0) this.$refs.saleAddonsList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.saleAddonsList && this.data.length > 0) this.$refs.saleAddonsList.loadMoreStatus = 'Loading'

            getSaleAddons(this.page, this.filters, this.sorts).then((response) => {
              const saleAddons = response.data

              saleAddons.data.forEach((saleAddon) => {
                let charactersName = []
                if (saleAddon.characters.length > 0) {
                  saleAddon.characters.forEach(item => {
                    charactersName.push(item.name ? item.name : this.$t('sales.addons.labels.all'))
                  })
                }

                let addonPrice = [saleAddon.characters[0].price, saleAddon.characters[0].price]
                if (saleAddon.characters.length > 0) {
                  saleAddon.characters.forEach(item => {
                    if (item.price < addonPrice[0]) {
                      addonPrice[0] = item.price
                    } else if (item.price > addonPrice[1]) {
                      addonPrice[1] = item.price
                    }
                  })
                }

                this.data.push({
                  id: saleAddon.id,
                  rowNumber: this.data.length + 1,
                  name: saleAddon.name || '',
                  price: {
                    value: addonPrice[0] === addonPrice[1] ? `${addComma(addonPrice[0])} ${this.$locale.currency()}` : `${addComma(addonPrice[0])} ${this.$locale.currency()} ${this.$t('sales.addons.labels.until')} ${addComma(addonPrice[1])} ${this.$locale.currency()}`,
                    // type: 'price'
                  },
                  character: charactersName.join('، ') || this.$t('sales.addons.labels.all'),
                  route: {
                    name: 'saleAddonProfile',
                    params: {id: saleAddon.id}
                  }
                })
              })

              this.total_count = saleAddons.pagination.total
              this.page = saleAddons.pagination.current_page + 1

              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.saleAddonsList) this.$refs.saleAddonsList.loadMoreStatus = ''
            }).catch((error) => {
              if (this.$refs.saleAddonsList && !axios.isCancel(error)) this.$refs.saleAddonsList.loadMoreStatus = 'Danger'
            })
          }
        }, 400)
      },
      setFilter (filters) {
        this.selectedFilters = filters
        let filters_list = {}
        Object.keys(filters).forEach((key) => {
          switch (key) {
            case 'name':
              if (filters[key].search !== '') filters_list.name = filters[key].search
              break

            case 'price':
              if (filters[key].search !== '') filters_list.price = `${filters[key].search},${  filters[key].type.id}`
              break

            case 'character':
              if (filters[key].search.value > 0) filters_list.character = filters[key].search.value
              break

            case 'financialStatus':
              if (filters[key].search !== '') filters_list.price = filters[key].search
              break
          }
        })

        this.data = []
        this.page = 1
        this.total_count = 0
        this.filters = filters_list
        this.$nextTick(() => {
          this.getSalesAddons()
        })
      },
      setSort (sorts) {
        const sorts_list = []
        Object.keys(sorts).forEach((key) => {
          switch (key) {
            case 'name':
              sorts_list.push(`order[0]=name,${  sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'price':
              sorts_list.push(`order[0]=price,${  sorts[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (sorts_list.length === 0) {
          this.sorts.push('order[0]=updated_at,desc')
        }

        this.data = []
        this.page = 1
        this.total_count = 0
        this.sorts = sorts_list
        this.$nextTick(() => {
          this.getSalesAddons()
        })
      },
      handleRowClicked () {
        this.setTableInfoToHistory()
      },
      handleReloadList () {
        this.data = []
        this.page = 1
        this.total_count = 0
        this.insertNewSaleAddonPromptStatus = false
        this.getSalesAddons()
      },
      handleClick (id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style scoped>

</style>
